@mixin header-font() {
    font-family: brandon-grotesque, sans-serif;
    font-weight: 400;
    font-style: normal;
    text-transform: uppercase;
}

h1,
h2,
h3,
h4 {
    @include header-font();
    sup {
        position: relative;
        top: 2px;
    }
}

h4 {
    margin-bottom: 0;
    margin-top: 30px;
    ~p {
        margin-top: 10px;
    }
}

.text-pages h2 {
    margin-top: 80px;
}

.page:not(.start-screen) h1 {
    margin-top: 30px;
    padding-bottom: 5px;
    margin-bottom: 35px;
}

p {
    font-family: adobe-caslon-pro, serif;
    font-weight: 400;
    font-style: normal;
    b {
        font-weight: 600;
    }
}

a {
    color: black;
    text-decoration: none;
    &:hover {
        text-decoration: underline;
    }
}

.App {
    @media screen and (min-width: 940px) {
        display: flex;
        flex-direction: row-reverse;
    }
    .main {
        flex: 1 1 50vw;
        .page {
            @media screen and (min-width: 940px) {
                min-height: 100vh;
            }
            width: 100%;
            &.start-screen {
                width: 100%;
                height: 70vh;
                display: flex;
                justify-content: center;
                margin-top: 50px;
                text-align: center;
                @media screen and (min-width: 940px) {
                    margin-top: 0;
                    align-items: center;
                }
                nav {
                    width: 100%;
                    background-color: white;
                    position: relative;
                    z-index: 10;
                    &.sticky {
                        position: fixed;
                        padding-top: 20px;
                        top: -20px;
                    }
                    .date {
                        font-size: 16px;
                        position: relative;
                        top: -15px;
                    }
                    .menu {
                        max-width: 500px;
                        margin: 0 auto;
                        display: flex;
                        justify-content: space-between;
                        padding: 10px;
                        .menu-items {
                            @include header-font();
                            margin: 20px 10px;
                        }
                    }
                }
            }
            &.with-content {
                padding-top: 175px;
                .content-container {
                    margin: 0 auto;
                    width: 80%;
                }
            }
            &.images {
                .image-thumbnails {
                    display: flex;
                    flex-wrap: wrap;
                    .image-container {
                        flex: 32% 1 1;
                        display: flex;
                        justify-content: center;
                        align-items: center;
                        padding: 2px;
                        .single-image {
                            max-width: 100%;
                            max-height: 100%;
                        }
                    }
                }
                .load-more-button {
                    padding: 10px 20px;
                    border: 1px rgb(99, 99, 99) solid;
                    margin: 20px auto;
                    text-align: center;
                    width: 50%;
                    background-color: white;
                    &:hover {
                        background-color: rgb(240, 240, 240);
                        cursor: pointer;
                    }
                }
                .preview {
                    position: fixed;
                    top: 0;
                    left: 0;
                    background-color: rgba(0, 0, 0, 0.8);
                    width: 100vw;
                    height: 100vh;
                    display: flex;
                    align-items: center;
                    justify-content: center;
                    z-index: 100;
                    img {
                        margin: 5%;
                        max-height: 90%;
                        max-width: 90%;
                    }
                }
                .images-header {
                    display: flex;
                    justify-content: space-between;
                    align-items: center;
                    label {
                        text-align: center;
                        border: 1px solid black;
                        padding: 10px 20px;
                        position: relative;
                        top: 0px;
                        background-color: white;
                        &:hover {
                            background-color: rgb(240, 240, 240);
                            cursor: pointer;
                        }
                    }
                    .upload {
                        position: relative;
                        input {
                            width: 100%;
                        }
                        #upload-button {
                            opacity: 0;
                            position: absolute;
                            z-index: -1;
                        }
                    }
                }
            }
        }
    }
    .dancers-container {
        flex: 1 1 50vw;
        height: 30vh;
        position: relative;
        padding-top: 100px;
        @media screen and (min-width: 940px) {
            padding-top: 0;
            height: 100vh;
        }
        .dancers {
            width: 100%;
            height: 100%;
            right: 0;
            top: 0;
            background-image: url("dancers.png");
            background-repeat: no-repeat;
            background-position: center;
            background-size: contain;
            @media screen and (min-width: 940px) {
                width: 50%;
                position: fixed;
                background-size: 60% auto;
            }
        }
    }
    .wishes {
        ul {
            padding-left: 25px;
            li {
                font-family: adobe-caslon-pro, serif;
                font-weight: 400;
                font-style: normal;
                margin-top: 15px;
                margin-bottom: 15px;
                b {
                    font-weight: 600;
                }
            }
        }
    }
}